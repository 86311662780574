<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-4 col-12">Listing</div>
        <div
        class="
            col-lg-8 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
        "
        ></div>
  </div>
  <div class="custom-ultima-datatable">
    <DataTable
      :value="mobileresponseList"
      :scrollable="true"
      scrollHeight="flex"
      :paginator="true"
      :rows="30"
      :lazy="true"
      :rowHover="true"
      :totalRecords="totalRecords"
      @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading"
      dataKey="ah1"
    >
      <template #empty>No Records found.</template>
      <template #loading>Loading data. Please wait...</template>
      <Column
        field="name"
        header="Full Name"
        headerStyle="width: 30%"
        bodyStyle="width: 30%"
      >
        <template #body="{ data }">
          <div>
            <div class="text-capitalize">
              {{ data.name ? data.name : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column
        field="mob"
        header="Mobile Number"
        headerStyle="width: 40%"
        bodyStyle="width: 40%"
      >
        <template #body="{ data }">
          <div>
            {{ data.mobile_number ? data.mobile_number : "N/A" }}
          </div>
        </template></Column
      >
      <Column
        field="category"
        header="Action"
        headerStyle="width: 20%"
        bodyStyle="width: 20%" class="d-flex justify-content-end"
      >
        <template #body="{ data }">
            <button
              type="button"
              class="btn custom-view-detail-btn"
              @click="mobilegetRedirectPage(data)"
            >
              View Details
            </button>
        </template></Column
      >
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../service/ApiService";
import router from "@/router";
export default {
  data() {
    return {
      mobileresponseList: [],
      totalRecords: 0,
      loading: false,
      page_no: 0,
      items_per_page: 30,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.getlisting({ page_no: this.page_no, items_per_page: this.items_per_page });
  },
  methods: {
    getlisting(item) {
        this.loading = true;
      this.ApiService.getlisting(item).then((data) => {
        if (data.success === true) {
          this.mobileresponseList = data.records;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.mobileresponseList = null;
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getlisting({ page_no: this.page_no, items_per_page: this.items_per_page });
    },
    mobilegetRedirectPage(data) {
      let routeData = router.resolve({
        name: "MobResDetails",
        params: { mobileId: data.mobile_number },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>